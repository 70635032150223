'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Appointment001 = function Appointment001() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var map = false;
  var markers = [];
  var clinics = [];
  var flkty = null;

  var reorderClinic = function reorderClinic() {
    var $clinics = [].slice.call(document.querySelectorAll('.map001 .list-container .clinic-item'));
    if ($clinics.length) {
      var orderedClinics = [];
      $clinics.forEach(function ($clinic) {
        var clinic = clinics.find(function (clinic) {
          return clinic.id === parseInt($clinic.dataset.id);
        });
        var distanceSpan = $clinic.querySelector('.clinic-distance span');
        var distance = 999999999;
        if (distanceSpan) {
          distance = parseInt(distanceSpan.textContent, 10);
        }
        orderedClinics.push({
          clinic: $clinic,
          distance: distance,
          lat: clinic.lat,
          lng: clinic.lng
        });
      });

      orderedClinics.sort(function (a, b) {
        return a.distance - b.distance;
      });

      map.setCenter(new google.maps.LatLng(parseFloat(orderedClinics[0].lat), parseFloat(orderedClinics[0].lng)));
      map.setZoom(15);

      var $container = document.querySelector('.map001 .list-container');
      $container.innerHTML = '';
      orderedClinics.forEach(function (orderedClinic) {
        $container.append(orderedClinic.clinic);
      });

      var $button = document.querySelector('.map001 .postal-code-form .form-button');
      $button.classList.remove('is-loading');
    }
  };

  var getDistance = function getDistance(origin) {
    if (origin.error === 'not_found') {
      // Postal code not found
      var $not_found_error = document.querySelector('.errors.for-input.not_found');
      $not_found_error.classList.remove('is-hidden');

      var $button = document.querySelector('.map001 .postal-code-form .form-button');
      $button.classList.remove('is-loading');
    } else if (!['QC', 'ON'].includes(origin.province)) {
      // Redirect user
      var formUrl = window.site.App.config('locale') === 'fr' ? '/rendez-vous-non-disponible' : '/appointment-unavailable';
      window.onbeforeunload = null;
      window.location.href = formUrl + '?outside-qc-on';
    } else {
      clinics.forEach(function (clinic) {
        // eslint-disable-next-line max-len
        var distance = Math.floor(window.site.PostalCodes.calculateDistance(origin.lat, origin.lng, clinic.lat, clinic.lng));
        clinic.distance = distance;

        var $clinic = document.querySelector('.form-process .list-container .clinic-item[data-id="' + clinic.id + '"]');

        $clinic.querySelector('.clinic-distance span').textContent = distance;
        $clinic.querySelector('.clinic-distance').classList.add('is-active');
      });

      reorderClinic();
    }
  };

  var sliderInit = function sliderInit() {
    var $slider = document.querySelector('.form-process .form-next-dates-list');

    var initSlider = function initSlider() {
      flkty = new Flickity($slider, {
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        groupCells: true
      });
    };

    initSlider();

    var $dropdown = document.querySelector('.form-process .form-choose-specialist .form-select');

    $dropdown.addEventListener('change', function (e) {
      setTimeout(function () {
        flkty.destroy();
        initSlider();
      }, 200);
    });
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    // Reinit localstorage if the user want to restart the process
    var $resetButtons = document.querySelectorAll('[data-set-storage]');
    if ($resetButtons.length) {
      $resetButtons.forEach(function ($resetButton) {
        $resetButton.addEventListener('click', function () {
          localStorage.removeItem('equilibre-appointment-state');
        });
      });
    }

    var $slider = document.querySelector('.form-process .form-next-dates-list');
    if ($slider) {
      sliderInit();
    }

    var dateInputs = document.querySelectorAll('[data-date-input]');
    if (dateInputs.length) {
      var format = site.App.config('locale') === 'fr' ? '99/99/9999' : '9999/99/99';
      var im = new Inputmask(format);
      im.mask(dateInputs);
    }

    inited = true;

    // Return success
    return true;
  };

  var initGeoLoc = function initGeoLoc() {
    var $map = document.querySelector('.form-process .map-list-container #map');
    clinics = JSON.parse($map.dataset.clinics);
    var postalCode = $map.dataset.postalcode;
    window.site.PostalCodes.getCoordinates(postalCode, getDistance);
  };

  var initMap = function initMap() {
    var $map = document.querySelector('.form-process .map-list-container #map');

    clinics = JSON.parse($map.dataset.clinics);

    var bounds = new google.maps.LatLngBounds();

    // Init map
    map = new google.maps.Map($map);

    var $nearestClinic = document.querySelector('.form-process .map-list-container .clinic-item.is-visible');

    clinics.forEach(function (clinic) {
      var $item = document.querySelector('.form-process .map-list-container .clinic-item[data-id="' + clinic.id + '"]');
      if ($item.classList.contains('is-visible')) {
        var latLng = { lat: parseFloat(clinic.lat), lng: parseFloat(clinic.lng) };
        var marker = new google.maps.Marker({
          map: map,
          position: latLng,
          draggable: false,
          icon: { url: 'data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyOCI+PHBhdGggZD0iTTEwIC42NjdBOS4xNCA5LjE0IDAgMDAuODMxIDkuODM0YzAgNS4wODMgOS4xNjcgMTcuNSA5LjE2NyAxNy41czkuMTY3LTEyLjQxNyA5LjE2Ny0xNy41QTkuMTQgOS4xNCAwIDAwMTAgLjY2N3pNMTAgMTRhNC4xMjcgNC4xMjcgMCAwMS00LjE2OC00LjE2NyA0LjEyNyA0LjEyNyAwIDAxNC4xNjctNC4xNjcgNC4xMjcgNC4xMjcgMCAwMTQuMTY3IDQuMTY3QTQuMTI3IDQuMTI3IDAgMDExMCAxNHoiIGZpbGw9IiMwMEJDRTciLz48L3N2Zz4=', scaledSize: new google.maps.Size(40, 40) },
          optimized: false
        });
        var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(loc);

        markers.push(marker);

        if ($item === $nearestClinic) {
          map.setCenter(latLng);
          map.setZoom(15);
        }
      }
    });

    // reorderClinic();
  };

  var initFirstMap = function initFirstMap() {
    var $map = document.querySelector('.form-process .map-list-container #map');

    // Init map
    map = new google.maps.Map($map, {
      center: { lat: 46.829853, lng: -71.254028 },
      zoom: 7
    });
  };

  var sliderDestroyCells = function sliderDestroyCells() {
    flkty.destroy();
  };

  var sliderReloadCells = function sliderReloadCells() {
    sliderInit();
  };

  var recenterMap = function recenterMap(clinicId) {
    var clinic = clinics.find(function (clinic) {
      return clinic.id === parseInt(clinicId);
    });
    var latLng = { lat: parseFloat(clinic.lat), lng: parseFloat(clinic.lng) };
    map.setCenter(latLng);
  };

  // Expose public methods & properties
  return {
    init: init,
    initFirstMap: initFirstMap,
    initMap: initMap,
    initGeoLoc: initGeoLoc,
    sliderDestroyCells: sliderDestroyCells,
    sliderReloadCells: sliderReloadCells,
    recenterMap: recenterMap
  };
}();